// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrlCore: 'https://api.stage.connectsx.com/api/core/',
  apiUrlAuth: 'https://api.stage.connectsx.com/api/auth/',
  apiUrlGudid: 'https://api.stage.connectsx.com/api/gudid/',
  apiUrlJob: 'https://api.stage.connectsx.com/api/job/',
  apiUrlAI: 'https://api.stage.connectsx.com/api/ai/',
  apiUrlUDIdentify: 'https://api.stage.connectsx.com/api/udidentify/',
  apiOrigin: 'https://api.stage.connectsx.com/',
  frontUrl: 'https://console.stage.connectsx.com/',
  debugMode: false,
  password: 'iougjkh79',
  quickBooksClientId: 'ABUw5ZWRKIkQphmgymVwFqKTNBBPjLWOouxbvKtkT3IHTc67uw',
  quickBooksClientSecret: 'eK6GjlkyOD1Y7qnihlqD5qssoG7WfGJMKWGLwSp2'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
